@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


body {
  font-family: "Nunito Sans", serif;
  font-optical-sizing: auto;
  font-weight: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
  margin: 0 !important;
}

* {
  font-family: "Nunito Sans", serif !important;
}