.lp-main-page-container {
  background-color: #eef3fb;
  height: 100% !important;
  min-height: 100% !important;
}

.lp-landing-hero-section-item {
  background-image: url("../Assets/landing-main-bg.png");
  padding: 80px 60px;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 850px;
}

.lp-meets-section-item {
  margin: 90px auto !important;
  padding: 50px 0px;
  max-width: 1550px !important;
}

.lp-head-section-item {
  max-width: 1550px !important;
}

.lp-point-header-item {
  max-width: 890px !important;
}

.lp-head-text {
  color: #ffffff !important;
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 1.2;
}

.lp-point-item {
  max-width: 30px !important;
  width: 30px !important;
}

.lp-text-item {
  max-width: calc(100% - 40px) !important;
  width: calc(100% - 40px) !important;
}

.lp-text-item span {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.lp-sub-points-item {
  margin: 15px 0px !important;
}

.lp-meet-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lp-meets-section-container {
  justify-content: space-between;
}

.lp-meet-img-item {
  /* max-width: 700px !important; */
  margin: 0 0 30px !important;
}

.lp-point-item img {
  margin: 0 auto !important;
}

.lp-meet-head-item {
  margin: 0px 0px 10px !important;
}

.lp-meet-head-item span {
  font-size: 35px !important;
  font-weight: 600 !important;
  color: black !important;
}

.lp-text-meet-item {
  max-width: calc(100% - 40px) !important;
  width: calc(100% - 40px) !important;
}

.lp-text-meet-item span {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #494949 !important;
}

.lp-highlight-text-item span {
  color: #00345e !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-style: italic;
}

.landing-app-bar {
  background-color: white !important;
  padding: 0px 60px;
  box-shadow: none !important;
}

.landing-logo {
  margin-left: -24px;
  width: auto;
  height: 50px;
  vertical-align: middle;
}

.lp-meet-text {
  display: block !important;
  font-style: italic !important;
  color: #00345e !important;
  margin-top: 8px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.responsiveLineHeight {
  line-height: 1.3;
}

.landing-app-toolbar {
  width: 100% !important;
  margin: 0 auto !important;
  max-width: 1550px !important;
}

.testing {
  margin-top: -0px;
}

.parent-meet {
  width: 100%;
  padding: 0px 60px;
}

/* @media only screen and (min-width: 0px) and (max-width: 1530px) {
    .lp-landing-hero-section-item {
        height: 730px !important;
    }

    .lp-head-text {
        color: #ffffff !important;
        font-size: 46px !important;
        font-weight: 500 !important;
    }

    .lp-text-item span {
        font-size: 18px !important;
        font-weight: 400 !important;
        color: #ffffff !important;
    }
}



@media only screen and (min-width: 1200px) and (max-width: 1470px) {
    .lp-meet-head-item span {
        font-size: 30px !important;
    }

    .lp-text-meet-item span {
        font-size: 17px !important;
        font-weight: 600 !important;
        color: #616161 !important;
    }

    .lp-meet-text {
        margin-top: 8px !important;
        font-size: 17px !important;
        font-weight: 700 !important;
    }
}


@media only screen and (min-width: 0px) and (max-width: 1680px) {
    .landing-app-toolbar {
        width: 90% !important;
        margin: 0 auto !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
    .lp-meet-img {
        width: 600px;
        height: 100%;
        object-fit: contain;
        margin: 0 auto !important;
    }
} */

/* Initially hide the image on all screen sizes */
/* .lp-meet-img-item {
  display: none;
} */

/* Styles for screens less than 576px wide (Extra small devices) */
@media (max-width: 575px) {
  /* Image stays hidden */
  .lp-meet-img-item {
    display: none;
  }
  .lp-head-text {
    font-size: 25px !important;
  }
  .lp-text-item span {
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #ffffff !important;
  }
  .lp-text-item {
    line-height: 1.3;
  }
  .lp-meet-head-item span {
    font-size: 23px !important;
    font-weight: 600 !important;
    color: black !important;
  }
  .lp-meets-section-item {
    margin: 0px auto !important;
    padding: 0px 0px;
    max-width: 1550px !important;
  }
  .lp-text-meet-item span {
    font-size: 14px !important;
  }
  .lp-text-meet-item {
    line-height: 1.3;
  }
  .lp-meet-text {
    font-size: 14px !important;
  }
  .lp-landing-hero-section-item {
    height: fit-content;
  }
  .forGap {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .landing-logo {
    height: 40px;
  }

  .lp-sub-points-item {
    margin: 10px 0px !important;
  }

  .lp-point-header-item {
    margin-bottom: 30px;
  }

  .lp-meet-img-item {
    /* max-width: 700px !important; */
    margin: 0 0 15px !important;
  }

  .lp-landing-hero-section-item {
    padding: 40px 20px 50px;
  }
  .parent-meet {
    padding: 0px 20px;
  }
  .landing-app-bar
  {
    padding: 0px 25px;
  }
  .lp-meets-section-item {
    padding: 40px 0px 60px;
  }
}

/* Styles for small devices (portrait tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  /* Image stays hidden */
  /* abcd */
  .lp-meet-img-item {
    display: none;
  }
  .lp-head-text {
    font-size: 40px !important;
  }
  .lp-text-item span {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #ffffff !important;
  }
  .lp-landing-hero-section-item {
    height: fit-content;
  }
  .forGap {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .lp-landing-hero-section-item {
    padding: 70px 40px 100px;
  }

  .parent-meet {
    padding: 0px 40px;
  }
  .landing-app-bar
  {
    padding: 0px 40px;
  }

  .lp-meets-section-item {
    margin: 0px auto !important;
    max-width: 1550px !important;
    padding: 50px 0px 60px;
  }
}

/* Styles for medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  /* Make the image visible */
  .lp-meet-img-item {
    display: none;
  }
  .lp-head-text {
    font-size: 45px !important;
  }
  .lp-text-item span {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #ffffff !important;
  }
  .lp-text-meet-item span {
    font-size: 18px !important;
  }
  .forGap {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .lp-landing-hero-section-item {
    padding: 80px 40px;
  }
  .parent-meet {
    padding: 0px 40px;
  }
  .landing-app-bar
  {
    padding: 0px 40px;
  }





  .lp-meets-section-item {
    margin: 30px auto !important;
    padding: 50px 0px;
    max-width: 1550px !important;
  }
}

/* Styles for large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  /* Make the image visible */
  .lp-meet-img-item {
    display: none;
  }
  .lp-head-text {
    font-size: 50px !important;
  }
  .lp-text-item span {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #ffffff !important;
  }
  .lp-text-meet-item span {
    font-size: 18px !important;
  }
  .forGap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  /* same */
  .lp-landing-hero-section-item
  {
    padding: 0px 60px;
  }
  .parent-meet {
    padding: 0px 60px;
  }
  .landing-app-bar
  {
    padding: 0px 60px;
  }




  .lp-meets-section-item {
    margin: 30px auto !important;
    max-width: 1550px !important;
  }
}

/* Styles for extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Make the image visible */
  .lp-meet-img-item {
    display: block;
  }
  .lp-head-text {
    font-size: 50px !important;
  }
  .lp-text-item span {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #ffffff !important;
  }

  .lp-text-meet-item span {
    font-size: 18px !important;
  }
  .forGap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  /* Both of these Should apply same vertical padding styles corrosponding to different screen sizes */
  .lp-landing-hero-section-item {
    padding: 0px 60px;
  }
  .parent-meet {
    padding: 0px 60px;
  }
  .landing-app-bar
  {
    padding: 0px 60px;
  }




  .lp-meets-section-item {
    margin: 90px auto !important;
    padding: 50px 0px 20px;
    max-width: 1550px !important;
  }
  .hero-child {
    margin: auto;
    max-width: 1550px !important;
  }
}

@media (max-width: 1680px) {
}
